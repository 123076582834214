
import PropTypes from "prop-types"
import React from "react"
import TextField from '@material-ui/core/TextField';
import { CompactPicker } from 'react-color'
import loadable from "@loadable/component";
const FontPicker = loadable(() => import("font-picker-react"))

const values = {}

class CountdownConfigForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            hours: "",
            minutes: "",
            seconds: "",
            color: "#fff",
            font: "Open Sans",
        }

        //
        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(e) {
        //
        const { onUpdate } = this.props;
        const { color, font } = this.state;

        if (e) {
            const target = e.target;
            const name = target.name;
            let value = target.value;

            // values[target.name] = target.value;
            if ((name === "hours" || name === "minutes" || name === "seconds") && value.length === 0) value = 0;
            //
            if (name === "hours" && value > 23) value = 23;
            else if (name === "hours" && value < 0) value = 0;
            //
            if ((name === "minutes" || name === "seconds") && value > 59) value = 59;
            else if ((name === "minutes" || name === "seconds") && value < 0) value = 0;

            //
            values[name] = value;

            this.setState(values)
        }

        // update with the color value
        values.color = color;
        values.font = font;

        onUpdate(values);
    }

    render() {
        const { title, hours, minutes, seconds, color, font } = this.state;
        return (
            <div className="clearfix">
               <div style={{ float: "left", width: "61%"}}>
                   <TextField name="title" label="Title" value={title} variant="outlined" fullWidth onChange={e => this.onUpdate(e)} style={{ marginBottom: "15px"}} />
                   <br/>
                   <br/>
                   <TextField name="hours" label="Hours" value={hours} variant="outlined" onChange={this.onUpdate} style={{width: "33.33%"}} inputProps={{maxLength: "2"}} />
                   <TextField name="minutes" label="Minutes" value={minutes} variant="outlined" onChange={this.onUpdate} style={{width: "33.33%"}} inputProps={{maxLength: "2"}} />
                   <TextField name="seconds" label="Seconds" value={seconds} variant="outlined" onChange={this.onUpdate} style={{width: "33.33%"}} inputProps={{maxLength: "2"}} />
               </div>
               <div style={{ float: "right", textAlign: "right", width: "auto" }}>
                   <CompactPicker
                      color={color}
                      onChangeComplete={color => {
                          this.setState({ color: color.hex })
                          this.onUpdate()
                      }}
                   />

                   {/**/}
                   <FontPicker
                      apiKey="AIzaSyDapCmOVRgg89vmZLNsSXUXqEaWDX1UXyg"
                      activeFontFamily={font}
                      onChange={newFont => {
                          this.setState({ font: newFont.family });
                          this.onUpdate()
                      }}
                      limit={150}
                   />

               </div>
            </div>
        );
    }
}

CountdownConfigForm.propTypes = {
    onUpdate: PropTypes.func
}

CountdownConfigForm.defaultProps = {

}

export default CountdownConfigForm