import PropTypes from "prop-types"
import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Alert, { ALERT_INFO } from "./Alert"
import Typography from '@material-ui/core/Typography';


const BASE_URL = "https://cd.twitch-countdown.com/countdown.html"

const blurredInputStyles = {
    background: "#000",
    textShadow: "0 0 5px hsla(0,0%,100%,.75)",
    color: "transparent",
    width: "100%",
    border: "1px solid #000",
    borderBottom: "1px solid #404040",
    padding: "15px 30px",
    display: "block",
    outline: "none !important",
    cursor: "pointer",
}
const iframeStyles = {
    border: "1px solid #000",
    margin: "0 0 20px",
}

/**
 *
 */
const CountdownPreview = ({ title, hours, minutes, seconds, color, font, onCoppiedToClipboard }) => {

    const src = encodeURI(`${BASE_URL}?title=${title}&hours=${hours}&minutes=${minutes}&seconds=${seconds}&color=${color.replace("#", "")}&font=${font}`);
    // console.log("src -> ", src);

    return (
       <>

           <br/>
           <Typography component="h2">
               Preview
           </Typography>
           <br/>
           {(hours > 0 || minutes > 0 || seconds > 0) ?
              <>
                  <Tooltip title="Click to copy widget url" placement="top" arrow>
                      <input
                         id="widgetUrl"
                         className="count-down"
                         type="text"
                         value={src}
                         style={blurredInputStyles}
                         readOnly={true}
                         onClick={(e) => {
                             //
                             const el = e.target;
                             el.select();
                             document.execCommand('copy');

                             onCoppiedToClipboard(el.value);
                         }}
                      />
                  </Tooltip>

                  <iframe title="preview" src={src} width="100%" height="250px" style={iframeStyles}></iframe>
              </>
              :
              <Alert message="Not enough data to render preview..." type={ALERT_INFO}/>
           }
       </>
    )
}

CountdownPreview.propTypes = {
    title: PropTypes.string,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
    color: PropTypes.string,
    onCoppiedToClipboard: PropTypes.func,
}

CountdownPreview.defaultProps = {
    title: "My countdown",
    hours: 24,
    minutes: 0,
    seconds: 0,
    color: "fff",
}

export default CountdownPreview