import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import CountdownConfigForm from "../components/countdown-config-form"
import CountdownPreview from "../components/countdown-preview"
import Snackbar from "@material-ui/core/Snackbar"

export default class CountdownFrom extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            hours: 0,
            minutes: 0,
            seconds: 0,
            color: "",
            font: "",
            copiedToClipboard: false,
        }

        //
        this.onFormUpdate = this.onFormUpdate.bind(this)
    }

    onFormUpdate(values) {
        //
        this.setState(values)
    }

    render() {
        const { title, hours, minutes, seconds, color, font, copiedToClipboard } = this.state

        return (
           <Layout>
               <SEO title="Twitch Countdown Overlay Configurator"/>
               <Typography component="h1">Countdown From</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   Here you're able to create an countdown overlay for your twitch stream.
                   <br/><br/>
                   Simply put your values for the title, hours, minutes and seconds in the form below and you can see the output in
                   the preview section under the form. Also your able to define the color for the text in the ticker. Just choose your
                   color and the preview will update with your new values.
               </Typography>
               <br/>
               <Typography component="h2">
                   Options
               </Typography>
               <br/>

               <Typography variant="body2" component="ul" style={{ marginLeft: "20px"}}>
                   <li>
                       <strong>Title</strong> -The title of your counter, leaf blank to hide.
                   </li>
                   <li>
                       <strong>Hours, Minutes, Seconds</strong> - The hours, minutes, seconds you wan't to count-down.
                   </li>
                   <li>
                       <strong>Color</strong> - The color used for the title and the counter.
                   </li>
                   <li>
                       <strong>Font</strong> - The font to render your ticker.
                   </li>
               </Typography>
               <br/>

               <CountdownConfigForm onUpdate={(values) => this.onFormUpdate(values)}/>

               <br/>

               <CountdownPreview
                  title={title}
                  hours={parseInt(hours, 10)}
                  minutes={parseInt(minutes, 10)}
                  seconds={parseInt(seconds, 10)}
                  color={color}
                  font={font}
                  onCoppiedToClipboard={(widgetUrl) => {
                      this.setState({ copiedToClipboard: true }, () => {
                          setTimeout(() => {
                              this.setState({ copiedToClipboard: false })
                          }, 3000)
                      })
                  }}
               />

               {copiedToClipboard ?
                  <Snackbar
                     open={true}
                     anchorOrigin={{
                         vertical: "bottom",
                         horizontal: "left",
                     }}
                     message="Link copied!"
                     autoHideDuration={6000}
                  />
                  :
                  null
               }
           </Layout>
        )
    }
}